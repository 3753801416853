import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import { Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useDeleteFieldGroup } from '../../../../../../hooks';

interface IDeleteGroupProps {
  field_group_uid: string;
}

export const DeleteGroup: React.FC<IDeleteGroupProps> = ({ field_group_uid }) => {
  const commonT = useTranslation(NSKeys.common);

  const { mutate, status } = useDeleteFieldGroup();

  return (
    <Popconfirm
      title={commonT.t('deleteConfirm')}
      onConfirm={(e) => {
        e?.stopPropagation();
        mutate({ field_group_uid });
      }}
      okText={commonT.t('yes')}>
      <Button
        danger
        loading={status === 'loading'}
        type={'text'}
        icon={<DeleteOutlined />}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Popconfirm>
  );
};
