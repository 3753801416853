import React from 'react';

import { theme as antTheme, Button, Divider, Drawer, Flex, Form, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { Paper } from '@components/Paper';
import { RenderFieldByType } from '@components/RenderFieldByType';

import { useGetProcessQuery } from '../../../../hooks';

import { AddFieldModal } from './components/AddFieldModal';
import { AddGroupFieldModal } from './components/AddGroupFieldModal';
import { DeleteGroup } from './components/DeleteGroup';
import { FieldOptionsPopover } from './components/FieldOptionsPopover';
import { GroupFieldName } from './components/GroupFieldName';

interface IProcessSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const OrderStructure: React.FC<IProcessSettingsProps> = ({ open, onClose }) => {
  const { token } = antTheme.useToken();
  const ordersT = useTranslation(NSKeys.orders);
  const [form] = Form.useForm();
  const params = useParams<{ processId: string }>();
  const { data: processData, status: loadingStatus } = useGetProcessQuery(params.processId);

  const renderContent = () =>
    loadingStatus === 'loading' ? (
      <Flex justify="center" align="center" style={{ minHeight: 300 }}>
        <Spin />
      </Flex>
    ) : (
      <Flex vertical gap={0}>
        {processData?.order_structure?.items?.map(
          (group, i) =>
            group.type === 'field_group' && (
              <Paper key={i}>
                <Flex justify="space-between">
                  <GroupFieldName field_group_uid={group.data.uid} name={group.data.name} />
                  {!!group?.data?.fields && group?.data?.fields?.length === 0 && (
                    <DeleteGroup field_group_uid={group.data.uid} />
                  )}
                </Flex>
                <Divider />
                <Form form={form} name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
                  <Flex vertical gap={0}>
                    {group.data.fields.map((field, i) => (
                      <div key={i} style={{ position: 'relative' }}>
                        <RenderFieldByType
                          form={form}
                          type={field.type}
                          label={field.name}
                          name={field.uid}
                          required={field.is_required}
                          preview
                        />
                        <div style={{ position: 'absolute', right: 0, top: 0 }}>
                          {!field.is_system && <FieldOptionsPopover field={field} />}
                        </div>
                      </div>
                    ))}
                    <AddFieldModal field_group_uid={group.data.uid} />
                  </Flex>
                </Form>
              </Paper>
            )
        )}
        {processData?.order_structure?.uid && (
          <AddGroupFieldModal order_structure_uid={processData.order_structure.uid} />
        )}
      </Flex>
    );

  return (
    <Drawer
      destroyOnClose
      title={
        <Flex vertical justify={'start'} style={{ marginLeft: 16 }}>
          <Typography.Title level={4} style={{ margin: 0, fontWeight: 800 }}>
            {processData?.name}
          </Typography.Title>
          <Typography.Text type="secondary" style={{ margin: 0 }}>
            {ordersT.t('orderStructure')}
          </Typography.Text>
        </Flex>
      }
      styles={{ body: { backgroundColor: token.colorBgLayout } }}
      placement="right"
      size="large"
      onClose={onClose}
      open={open}>
      {renderContent()}
    </Drawer>
  );
};
