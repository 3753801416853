import React, { Suspense } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider, Spin } from 'antd';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import weekdays from 'dayjs/plugin/weekday';
import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import ptLocale from 'i18n-iso-countries/langs/pt.json';
import ruLocale from 'i18n-iso-countries/langs/ru.json';
import { useTranslation } from 'react-i18next';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';

import { EmployeeProvider } from '@app/pages/Employees/providers';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { getAntLanguage } from '@app/services/i18n';
import { GET_THEME_CONFIG } from '@app/theme/antd';
import ErrorBoundary from '@components/ErrorBoundary';
import { AppLayout } from '@layouts/AppLayout';
import { AuthLayout } from '@layouts/AuthLayout';
import { DashboardLayout } from '@layouts/DashboardLayout';
import ChangePassword from '@pages/Auth/ChangePassword';
import Confirm from '@pages/Auth/Confirm';
import Invite from '@pages/Auth/Invite';
import Login from '@pages/Auth/Login';
import Registration from '@pages/Auth/Registration';
import ResetPassword from '@pages/Auth/ResetPassword';
import CatalogAdd from '@pages/Catalogs/pages/Add';
import CatalogEdit from '@pages/Catalogs/pages/Edit';
import CatalogList from '@pages/Catalogs/pages/List';
import SingleCatalog from '@pages/Catalogs/pages/Single';
import CatalogAddProduct from '@pages/Catalogs/pages/Single/pages/AddProduct';
import Cart from '@pages/Catalogs/pages/Single/pages/Cart';
import CatalogEditProduct from '@pages/Catalogs/pages/Single/pages/EditProduct';
import CatalogSingleProduct from '@pages/Catalogs/pages/Single/pages/SingleProduct';
import AddContacts from '@pages/Contacts/pages/Add';
import EditContact from '@pages/Contacts/pages/Edit';
import ContactsList from '@pages/Contacts/pages/List';
import SingleContact from '@pages/Contacts/pages/Single';
import { ContactRecipientsProvider } from '@pages/Contacts/providers';
import EditEmployee from '@pages/Employees/pages/EditEmployee';
import EmployeesList from '@pages/Employees/pages/EmployeesList';
import SingleEmployee from '@pages/Employees/pages/SingleEmployee';
import Groups from '@pages/Groups';
import { GroupProvider } from '@pages/Groups/providers';
import { NotFound } from '@pages/NotFound';
import AddOrderApprovalChain from '@pages/OrderApprovalChains/pages/Add';
import ConfirmationList from '@pages/OrderApprovalChains/pages/List';
import Confirmation from '@pages/OrderApprovalChains/pages/Single';
import { ConfirmationProvider } from '@pages/OrderApprovalChains/providers/confirmationProvider';
import ProcessesContainer from '@pages/Processes/pages/Container';
import EditOrder from '@pages/Processes/pages/Orders/pages/Edit';
import OrdersList from '@pages/Processes/pages/Orders/pages/List';
import SingleOrder from '@pages/Processes/pages/Orders/pages/Single';
import { OrderItemsProvider } from '@pages/Processes/pages/Orders/providers';
import AddProduct from '@pages/Products/pages/AddProduct';
import EditProduct from '@pages/Products/pages/EditProduct';
import ProductsList from '@pages/Products/pages/List';
import SingleProduct from '@pages/Products/pages/SingleProduct';
import ProfileSettings from '@pages/ProfileSettings';
import AddRecipient from '@pages/Recipients/pages/Add';
import EditRecipient from '@pages/Recipients/pages/Edit';
import RecipientsList from '@pages/Recipients/pages/List';
import SingleRecipient from '@pages/Recipients/pages/Single';
import { RecipientContactsProvider } from '@pages/Recipients/providers';
import SpaceSettings from '@pages/SpaceSettings';
import { SpaceProvider } from '@pages/SpaceSettings/SpaceSettingsProvider';
import WirehouseAdd from '@pages/Warehouses/pages/Add';
import WarehousesList from '@pages/Warehouses/pages/List';
import WarehouseSingle from '@pages/Warehouses/pages/Single';
import { WarehouseProvider } from '@pages/Warehouses/pages/Single/WarehouseProvider';

import { GlobalStyle, SuspenseContainer } from './styles';

// require('dayjs/locale/ru');
// require('dayjs/locale/pt-br');

dayjs.extend(localeData);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekdays);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useTranslation();
  const theme = useLocalSelector((state) => state.currentSession.theme);
  const colorPrimary = useLocalSelector((state) => state.currentSession.colorPrimary);
  countries.registerLocale(enLocale);
  countries.registerLocale(ruLocale);
  countries.registerLocale(ptLocale);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route
        path="/"
        element={
          <ErrorBoundary>
            <AppLayout>
              <Outlet />
            </AppLayout>
          </ErrorBoundary>
        }>
        <Route element={<AuthLayout />}>
          <Route path="" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset/:userId" element={<ChangePassword />} />
          <Route path="/invite/:id" element={<Invite />} />
          <Route path="/confirm/:id" element={<Confirm />} />
          <Route path="*" element={<Login />} />
        </Route>
        <Route path="/dashboard" element={<Outlet />}>
          <Route element={<DashboardLayout />}>
            <Route path="" element={<Navigate to="profile" />} />

            <Route path="profile" element={<ProfileSettings />} />

            <Route
              path="account"
              element={
                <SpaceProvider>
                  <SpaceSettings />
                </SpaceProvider>
              }
            />

            <Route
              path="catalogs"
              element={<Outlet />}
              handle={{
                name: 'catalogs',
              }}>
              <Route path="" element={<CatalogList />} />
              <Route
                path=":id"
                element={<Outlet />}
                handle={{
                  name: ':catalogId',
                }}>
                <Route path="" element={<SingleCatalog />} />
                <Route
                  path=":productId"
                  element={<CatalogSingleProduct />}
                  handle={{
                    name: ':productId',
                  }}
                />
                <Route
                  path="cart"
                  element={<Cart />}
                  handle={{
                    name: 'cart',
                  }}
                />
                <Route path="add" element={<CatalogAddProduct />} />
                <Route
                  path="edit/:productId"
                  element={<CatalogEditProduct />}
                  handle={{
                    name: ':productId',
                  }}
                />
              </Route>
              <Route
                path="add"
                element={<CatalogAdd />}
                handle={{
                  name: 'catalogsAdd',
                }}
              />
              <Route
                path="edit/:id"
                element={<CatalogEdit />}
                handle={{
                  name: 'catalogsEdit',
                }}
              />
            </Route>

            <Route
              path="employees"
              element={<Outlet />}
              handle={{
                name: 'employees',
              }}>
              <Route path="" element={<EmployeesList />} />
              <Route
                path=":id"
                element={
                  <EmployeeProvider>
                    <SingleEmployee />
                  </EmployeeProvider>
                }
                handle={{
                  name: ':employeeId',
                }}
              />
              <Route
                path="edit/:id"
                element={
                  <EmployeeProvider>
                    <EditEmployee />
                  </EmployeeProvider>
                }
                handle={{
                  name: 'editEmployee',
                }}
              />
            </Route>

            <Route
              path="products"
              handle={{
                name: 'nomenclature',
              }}
              element={<Outlet />}>
              <Route path="" element={<ProductsList />} />
              <Route
                path=":id"
                element={<SingleProduct />}
                handle={{
                  name: 'singleNomenclature',
                }}
              />
              <Route
                path="add"
                element={<AddProduct />}
                handle={{
                  name: 'addNomenclature',
                }}
              />
              <Route
                path="edit/:id"
                element={<EditProduct />}
                handle={{
                  name: 'editNomenclature',
                }}
              />
            </Route>

            <Route
              path="groups"
              element={<Outlet />}
              handle={{
                name: 'groups',
              }}>
              <Route
                path=""
                element={
                  <GroupProvider>
                    <Groups />
                  </GroupProvider>
                }
              />
            </Route>

            <Route path="processes" element={<Outlet />}>
              <Route path="" element={<ProcessesContainer />} />
              <Route path=":processId" element={<Outlet />}>
                <Route
                  path="orders"
                  element={<Outlet />}
                  handle={{
                    name: 'orders',
                  }}>
                  <Route path="" element={<OrdersList />} />
                  <Route
                    path=":orderId"
                    element={<SingleOrder />}
                    handle={{
                      name: ':ordersId',
                    }}
                  />
                  <Route
                    path="edit/:orderId"
                    element={
                      <OrderItemsProvider>
                        <EditOrder />
                      </OrderItemsProvider>
                    }
                    handle={{
                      name: ':ordersId',
                    }}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="orderApprovalChains"
              element={<Outlet />}
              handle={{
                name: 'orderApprovalChains',
              }}>
              <Route
                path=""
                element={<ConfirmationList />}
                handle={{
                  name: 'orderApprovalChains',
                }}
              />
              <Route
                path="add"
                element={
                  <GroupProvider>
                    <ConfirmationProvider>
                      <AddOrderApprovalChain />
                    </ConfirmationProvider>
                  </GroupProvider>
                }
                handle={{
                  name: 'addOrderApprovalChain',
                }}></Route>
              <Route
                path=":id"
                element={
                  <GroupProvider>
                    <ConfirmationProvider>
                      <Confirmation />
                    </ConfirmationProvider>
                  </GroupProvider>
                }
                handle={{
                  name: ':id',
                }}
              />
            </Route>

            <Route
              path="recipients"
              element={<Outlet />}
              handle={{
                name: 'recipients',
              }}>
              <Route path="" element={<RecipientsList />} />
              <Route
                path="add"
                element={
                  <RecipientContactsProvider>
                    <AddRecipient />
                  </RecipientContactsProvider>
                }
                handle={{
                  name: 'recipientsAdd',
                }}
              />
              <Route
                path=":id"
                element={<SingleRecipient />}
                handle={{
                  name: ':recipientId',
                }}
              />
              <Route
                path="edit/:id"
                element={
                  <RecipientContactsProvider>
                    <EditRecipient />
                  </RecipientContactsProvider>
                }
                handle={{
                  name: ':recipientId',
                }}
              />
            </Route>

            <Route
              path="contacts"
              element={<Outlet />}
              handle={{
                name: 'contacts',
              }}>
              <Route path="" element={<ContactsList />} />
              <Route
                path="add"
                element={
                  <ContactRecipientsProvider>
                    <AddContacts />
                  </ContactRecipientsProvider>
                }
                handle={{
                  name: 'contactsAdd',
                }}
              />
              <Route
                path=":id"
                element={<SingleContact />}
                handle={{
                  name: ':contactId',
                }}
              />
              <Route
                path="edit/:id"
                element={
                  <ContactRecipientsProvider>
                    <EditContact />
                  </ContactRecipientsProvider>
                }
                handle={{
                  name: ':contactId',
                }}
              />
            </Route>

            <Route path="warehouses" element={<Outlet />} handle={{ name: 'warehouses' }}>
              <Route path="" element={<WarehousesList />} />
              <Route
                path="add"
                element={<WirehouseAdd />}
                handle={{
                  name: 'warehousesAdd',
                }}
              />
              <Route
                path=":id"
                element={
                  <WarehouseProvider>
                    <WarehouseSingle />
                  </WarehouseProvider>
                }
                handle={{
                  name: ':warehouseId',
                }}
              />
            </Route>
          </Route>

          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    )
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      <ConfigProvider theme={GET_THEME_CONFIG(theme, colorPrimary)} locale={getAntLanguage()}>
        {/* <ConfigProvider theme={{ ...ANT_THEME, algorithm: theme.darkAlgorithm }} locale={getAntLanguage()}> */}
        <Suspense
          fallback={
            <SuspenseContainer>
              <Spin />
            </SuspenseContainer>
          }>
          <GlobalStyle $colorPrimary={colorPrimary} />
          <RouterProvider router={router} />
        </Suspense>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
