import React from 'react';

import { Image } from 'antd';

import { FieldType } from '@app/models/interfaces';
import { formatDate, formatDateAndTime } from '@app/services/date';
import { StyledCarousel } from '@components/StyledCarousel';

export const getDisplayValueKey = ({ type, alias, data }: { type: FieldType; alias: string; data: any }) => {
  if (!data?.[alias] && data?.[alias] !== 0) {
    return '';
  }
  if (type === 'datetime') {
    return formatDateAndTime(data?.[alias]);
  }
  if (type === 'date') {
    return formatDate(data?.[alias]);
  }
  if (type === 'user') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'catalog') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'recipient') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'contact') {
    return data?.[alias]?.name ?? '';
  }
  if (type === 'address') {
    return data?.[alias]?.['as_string'] ?? '';
  }
  if (type === 'multi_image') {
    return (
      <div style={{ display: 'block', width: '200px', height: '150px' }}>
        <StyledCarousel dots={{ className: 'carousel' }} fade lazyLoad="ondemand">
          {data?.[alias]?.map(({ uid, variants }: any) => (
            <Image
              key={uid}
              src={variants.small?.url}
              height={150}
              wrapperClassName="imageContainer"
              className="image"
            />
          ))}
        </StyledCarousel>
      </div>
    );
  }
  return data?.[alias];
};
