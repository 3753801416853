import React from 'react';

import { theme as antTheme, Card, Divider, Drawer, Flex, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { useGetProcessQuery } from '../../../../hooks';

import { StageText } from './components/StageText';
import { StatusBlock } from './components/StatusBlock';

interface IProcessSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const ProcessSettings: React.FC<IProcessSettingsProps> = ({ open, onClose }) => {
  const { token } = antTheme.useToken();
  const ordersT = useTranslation(NSKeys.orders);
  const params = useParams<{ processId: string }>();
  const { data: processData, status: loadingStatus } = useGetProcessQuery(params.processId);

  const renderContent = () =>
    loadingStatus === 'loading' ? (
      <Flex justify="center" align="center" style={{ minHeight: 300 }}>
        <Spin />
      </Flex>
    ) : (
      <Flex vertical gap={16}>
        {processData?.stages.map((stage) => (
          <Card key={stage.uid} styles={{ body: { padding: '12px 24px' } }}>
            <Flex vertical gap={4} justify="start" align="baseline">
              <StageText name={stage.name} stage_uid={stage.uid} />
              <Divider plain style={{ margin: '4px' }} />
              {stage.statuses.map((status) => (
                <StatusBlock name={status.name} status_uid={status.uid} color={status.color} key={status.uid} />
              ))}
            </Flex>
          </Card>
        ))}
      </Flex>
    );

  return (
    <Drawer
      destroyOnClose
      title={
        <Flex vertical justify={'start'} style={{ marginLeft: 16 }}>
          <Typography.Title level={4} style={{ margin: 0, fontWeight: 800 }}>
            {processData?.name}
          </Typography.Title>
          <Typography.Text type="secondary" style={{ margin: 0 }}>
            {ordersT.t('processSettings')}
          </Typography.Text>
        </Flex>
      }
      placement="right"
      size="large"
      onClose={onClose}
      styles={{ body: { backgroundColor: token.colorBgLayout } }}
      open={open}>
      {renderContent()}
    </Drawer>
  );
};
