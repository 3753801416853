import * as React from 'react';

import { ReactNode } from 'react';

import { Carousel, CarouselProps, theme } from 'antd';

import { CarouselContainer } from './styles';

interface IProps extends CarouselProps {
  children?: ReactNode;
}

export const StyledCarousel: React.FC<IProps> = ({ children, ...rest }) => {
  const { token } = theme.useToken();

  return (
    <CarouselContainer $color={token.colorPrimary}>
      <Carousel {...rest}>{children}</Carousel>
    </CarouselContainer>
  );
};
