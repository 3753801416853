import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Flex, Skeleton, Typography } from 'antd';

import { useUpdateFieldGroup } from '../../../../../../hooks';

interface IProps {
  name: string;
  field_group_uid: string;
}

export const GroupFieldName: React.FC<IProps> = ({ name, field_group_uid }) => {
  const [editableStr, setEditableStr] = React.useState(name);

  React.useEffect(() => {
    setEditableStr(name);
  }, [name]);

  const { mutate, status } = useUpdateFieldGroup();

  const onEdit = (value: string) => {
    if (name !== value) {
      mutate({ name: value, field_group_uid });
    }
  };

  return (
    <Flex style={{ marginBottom: 4, width: '100%' }} align="center">
      {status === 'loading' ? (
        <Skeleton.Input active />
      ) : (
        <Typography.Paragraph
          editable={{ onChange: onEdit, icon: <EditOutlined style={{ fontSize: 18 }} /> }}
          style={{ width: '100%', margin: 0, fontSize: 20, display: 'flex', alignItems: 'center' }}>
          {editableStr}
        </Typography.Paragraph>
      )}
    </Flex>
  );
};
