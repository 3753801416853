import styled from 'styled-components';

export const CarouselContainer = styled.div<{ $color: string }>`
  .ant-carousel {
    .slick-list {
      .ant-image {
        //cursor: default;
      }
    }
    .carousel {
      li button {
        background: ${({ $color }) => $color};
      }
    }

    .imageContainer {
      width: 200px;
      height: 150px;
      display: flex;
      border-radius: 0;
    }

    .image {
      object-fit: contain;
      border-radius: 0;
    }
  }
`;
