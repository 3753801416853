import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  //margin: 24px 24px 0 24px;
  max-height: calc(100vh - 113px);
  display: flex;
  flex: 1;
  overflow-y: auto;
`;
